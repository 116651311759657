import {
  faExchange,
  faStar,
  faUserFriends,
  faFilter,
  faCheckSquare,
  faSquare,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useState } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { prettyAmount, prettyCount } from "../../services/number"
import { darkTheme, lightTheme } from "../../services/theme"
import HtmlTooltip from "../widgets/html-tooltip"

const FeedbackFilter: FunctionComponent = () => {
  const { commentFilters, updateCommentFilters } = useFilters()
  const { user } = useUser()
  const [threshold, setThreshold] = useState(commentFilters.threshold)

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onThresholdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value.length == 0) {
        setThreshold(0)
        return
      } else {
        let amount = parseFloat(event.currentTarget.value)
        if (amount > 0) {
          setThreshold(amount)
        } else {
          setThreshold(0)
        }
      }
    },
    []
  )

  const onSubmit = useCallback(() => {
    updateCommentFilters({ threshold: threshold })
  }, [threshold])

  const onReadChange = useCallback(() => {
    updateCommentFilters({ unread: !commentFilters.unread })
  }, [commentFilters])

  return (
    <>
      {commentFilters.owned && (
        <div className="accordion">
          <section>
            <header>
              <h1>
                <FontAwesomeIcon icon={faFilter} size="lg" />
                <span>Feedback filter</span>
              </h1>
            </header>
          </section>

          <section>
            <div className="content">
              <div className="tt">
                <div className="tr" style={{ fontSize: "14px" }}>
                  <div
                    className="tc"
                    style={{ width: "80px", verticalAlign: "middle" }}
                  >
                    Show from
                  </div>
                  <div className="tc">
                    <div className="amountCell">
                      <input
                        id="threshold"
                        type="number"
                        step="1.00"
                        value={threshold}
                        onChange={onThresholdChange}
                        className="amount"
                      />
                    </div>
                  </div>

                  <div className="tcs" style={{ textAlign: "center" }}>
                    <div className="submitCell">
                      <button onClick={onSubmit} className="submit">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tr">
                  <div className="tc" style={{ verticalAlign: "middle" }}>
                    Unread only
                  </div>
                  <div
                    className="tc thumbsUpContainer"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                    onClick={onReadChange}
                  >
                    {commentFilters.unread ? (
                      <FontAwesomeIcon icon={faCheckSquare} size="lg" />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} size="lg" />
                    )}
                  </div>
                </div>
                <div className="tcs" style={{}}></div>
              </div>
            </div>
          </section>
          <style jsx>{`
            .thumbsUpContainer {
            }
            .thumbsUpContainer:hover {
              cursor: pointer;
              color: blue;
            }

            .submitCell {
              width: 95px;
              display: table-cell;
              vertical-align: middle;
              text-align: right;
            }
            .submit {
              width: 90px;
              font-family: "Roboto";
              padding: 2px;

              color: inherit;
              background-color: transparent;
              border: 1px solid ${css.borderColor};
              padding: 5px;
              transition: 200ms border-color, 200ms background-color,
                200ms color;
              z-index: 1;
              border-radius: 0.3em;
              background-color: ${css.activeButtonBackgroundColor};
            }
            .submit:hover {
              box-shadow: 0 0 0 1px ${css.focusColor};
            }

            .amountCell {
              display: table-cell;
              vertical-align: middle;
              /*padding-left: 10px;
              padding-right: 5px;*/
              width: 80px;
            }
            .amount {
              width: 100%;
              text-align: right;
              font-family: "Roboto";
              font-size: 14px;
              background-color: ${css.activeButtonBackgroundColor};
              border: 1px solid ${css.inputBorderColor};
              transition: 200ms background-color, 200ms border-color,
                200ms box-shadow;
              border-radius: 0.4em;
              padding: 5px;
              resize: vertical;
              width: 100%;
              color: inherit;
              /*min-height: 3.3em;*/
              line-height: 18px;
            }
            .amount:focus,
            .amount:hover {
              box-shadow: 0 0 0 1px ${css.focusColor};
            }
            .tt {
              display: table;
              font-family: "Roboto";
              width: 100%;
            }
            .tr {
              display: table-row;
              height: 34px;
            }
            .tc {
              display: table-cell;
            }
            .tcs {
              display: table-cell;
              text-align: center;
            }

            .accordion {
              background-color: ${css.backgroundColor};
              border: 1px solid ${css.borderColor};
              transition: 200ms background-color, 200ms border-color;
              border-radius: 0.3em;
              font-size: 0.9em;
              user-select: none;
            }
            section:not(:first-child) {
              border-top: 1px solid ${css.borderColor};
              transition: 200ms border-color;
            }
            .content {
              border-top: 1px solid ${css.borderColor};
              flex-wrap: wrap;
              display: inline-flex;
              width: 100%;
              padding: 20px;
            }
            header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1em;
              font-family: Roboto;
            }
            header > :global(:not(:first-child)) {
              margin-left: 1em;
            }

            h1 {
              margin: 0;
              padding: 0;
              font-size: inherit;
              font-style: inherit;
              font-weight: normal;
              display: flex;
              align-items: center;
              text-transform: uppercase;
              font-weight: lighter;
            }
            h1 > *:not(:first-child) {
              margin-left: 1em;
            }
          `}</style>
        </div>
      )}
    </>
  )
}

export default FeedbackFilter
