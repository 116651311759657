import { navigate } from "gatsby"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useItems } from "../../hooks/use-items"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"
import { useUser } from "../../hooks/use-user"
import { Item, LikeType, MuteType } from "../../types"
import Comments from "../comment/comments"
import Donations from "../comment/donations"
import ItemPreview from "../item/preview"
import CachedFeed, { FeedInfo, ScrollEvent } from "./cached-feed"

let pushState = true
var cache: { [key: string]: FeedInfo } = {}
var nextFeedId = 0

type Props = {
  initialChannelIds: string[]
  kind: string | undefined
}

const Feeds: FunctionComponent<Props> = ({ initialChannelIds, kind }) => {
  const { filters, updateFilters, updateCommentFilters } = useFilters()
  const { user } = useUser()
  const { setSideMode } = useSideMode()
  const [currentFeed, setCurrentFeed] = useState<FeedInfo>({
    feed: -1,
    reset: true,
    scroll: 0,
    filters: "",
    ts: 0,
    kind: "items",
  })
  const [scrollEvent, setScrollEvent] = useState<ScrollEvent>({
    feed: -1,
    ts: 0,
  })
  const [commentItem, setCommentItem] = useState<Item | null>(null)
  const { updateItem, likeItem } = useItems([])
  const [showFeedback, setShowFeedback] = useState(false)

  const onScroll = () => {
    const isTriggered =
      window.scrollY + window.innerHeight >=
      document.body.scrollHeight - window.innerHeight / 2
    if (isTriggered && currentFeed.feed != -1) {
      //console.log(">>> scroll - " + currentFeed.feed)
      if (commentItem == null) {
        setScrollEvent({ feed: currentFeed.feed, ts: Date.now() })
        //console.log("current feed " + currentFeed.feed)
        //  setCurrentFeed({...currentFeed, reset: false, ts: Date.now()})
      }
    }
  }
  //console.log("Feeds ======================")

  useEffect(() => {
    //console.log("feeds:: useEffect currentFeed")
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual"
    }
    window.addEventListener("popstate", popStateListener)
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("popstate", popStateListener)
      window.removeEventListener("scroll", onScroll)
    }
  }, [currentFeed, commentItem])

  const showFeed = useCallback((feed: FeedInfo) => {
    //console.log("feeds::want to show " + feed.feed)
    let e = document.getElementById("dfeed_" + feed.feed)
    if (e && e.classList.contains("hidden")) {
      //let f = JSON.parse(feed.filters)
      //console.log("feeds::show " + feed.feed) // + " " + JSON.stringify(f.tags))
      e.classList.remove("hidden")
      if (feed.filters in cache) {
        //console.log("feeds::show " + JSON.stringify(cache[feed.filters]))
        setTimeout(function () {
          if (feed.filters in cache && cache[feed.filters])
            scroll(cache[feed.filters].scroll, 30)
        }, 30)
      }
    }
  }, [])

  const scroll = useCallback((position: number, numTries: number) => {
    //console.log("scroll " + document.documentElement.scrollTop)
    if (document.documentElement.scrollHeight >= position) {
      document.documentElement.scrollTop = position
    } else if (numTries > 0) {
      setTimeout(function () {
        scroll(position, numTries--)
      })
    }
  }, [])

  const popStateListener = useCallback(
    event => {
      if (event.state && event.state.filters) {
        //console.log("feeds::************ popStateListener " + event.state.scroll + " " + event.state.filters)
        let f = JSON.parse(event.state.filters)
        /*console.log(
          "feeds:: <<<<<<<<< nextFeed- " +
            event.state.kind +
            " " +
            event.state.feed +
            " " +
            JSON.stringify(f.tags)
        )*/
        //setCommentItem(null)

        pushState = false
        updateFilters(JSON.parse(event.state.filters))
        event.preventDefault()
      }
    },
    [setCommentItem]
  )

  const hideAllFeeds = useCallback(() => {
    for (var i = 0; i < 5; i++) {
      let e = document.getElementById("dfeed_" + i)
      if (e && e.classList.contains("hidden") == false) {
        //console.log("feeds::hide " + i)
        e.classList.add("hidden")
      }
    }
    if (commentItem) {
      //console.log(">>>>> setCommentItem(null)")
      setCommentItem(null)
    }
    setSideMode(SideMode.Content)
    updateCommentFilters({ owned: false })
  }, [commentItem, setCommentItem])

  const switchFeeds = useCallback(() => {
    /*console.log(
      "useEffect " +
        (currentFeed ? currentFeed.feed : "no feed") +
        " " +
        (history.state ? history.state.kind : "no state")
    )
    if (initialChannelIds.length) console.log(initialChannelIds[0])
    if (filters.channel_ids.length) console.log(filters.channel_ids[0])*/

    /*if (history.state)
      console.log(
        "switchFeed " +
          currentFeed.feed +
          " " +
          kind +
          " " +
          JSON.stringify(history.state)
      )
    else
      console.log(
        "switchFeed " + currentFeed.feed + " " + kind + " " + history.state
      )*/

    if (
      currentFeed.feed == -1 ||
      filters.mod > 0 ||
      (history.state && history.state.kind == "items") ||
      kind == "channel" ||
      kind == undefined
    ) {
      //console.log("checking")
      let key = JSON.stringify(filters)

      if (currentFeed.feed == -1 || filters.mod > 0) {
        //console.log("feeds::first feed")
        cache = {}
        hideAllFeeds()
        let nextFeed: FeedInfo = {
          feed: 0,
          reset: true,
          scroll: 0,
          filters: key,
          ts: Date.now(),
          kind: "items",
        }
        history.replaceState(nextFeed, "Content", null)
        setCurrentFeed(nextFeed)
        cache[key] = nextFeed
      } else {
        if (currentFeed.filters == key && commentItem == null) {
          //console.log("***** skip")
          return
        }
        if (kind === "channel" && filters.channel_ids.length == 0) {
          console.log("going to the root")
          navigate("/")
        }

        //console.log("feeds::------------ switchFeed " + key)

        let nextFeed: FeedInfo = {
          feed: 0,
          reset: false,
          scroll: 0,
          filters: key,
          ts: Date.now(),
          kind: "items",
        }
        if (key in cache) {
          nextFeed = cache[key]
          nextFeed.reset = false
          //console.log("feeds:: found in cache. reusing " + nextFeed.feed)
        } else {
          nextFeedId = nextFeedId + 1
          if (nextFeedId == 5) nextFeedId = 0
          for (let key in cache) {
            if (cache[key].feed == nextFeedId) delete cache[key]
          }
          nextFeed.feed = nextFeedId
          nextFeed.reset = true
          cache[key] = nextFeed
        }

        if (pushState) {
          if (window.history && window.history.pushState) {
            if (history.state && history.state.filters) {
              /*console.log(
                "feeds >>>>>>>>> pushState " +
                  history.state.filters +
                  " " +
                  document.documentElement.scrollTop
              )*/
              if (history.state.filters in cache) {
                //console.log("saved to " + history.state.feed)
                cache[history.state.filters].scroll =
                  document.documentElement.scrollTop
              }
            }

            //let data = {feed: 0, scroll: document.documentElement.scrollTop, reset: false, filters: key, ts: Date.now()}
            history.pushState(nextFeed, "Content", null)
          }
        } else pushState = true

        hideAllFeeds()

        if (nextFeed.reset == false) showFeed(nextFeed)
        let f = JSON.parse(nextFeed.filters)
        /*console.log(
          "feeds:: >>>>>>>>> nextFeed " +
            nextFeed.feed +
            " " +
            JSON.stringify(f.tags)
        )*/
        setCurrentFeed(nextFeed)
      }
    }
    /*}        
        else if (history.state && history.state.kind == "items") {
            //hideAllFeeds()
            //setCurrentItemsFeed({feed: 100, reset: true, scroll: 0, filters: "", ts: Date.now(), kind: "items"})
        } */
  }, [filters, currentFeed, kind, pushState])

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    /*console.log(
      "Feeds: useEffect() ----------------------------------------------------------------------------------------" +
        filters
    )*/
    switchFeeds()
  }, [filters])

  const comment = useCallback((item: Item) => {
    //console.log("showChannelItems " + item.id)
    if (window.history && window.history.pushState) {
      if (history.state && history.state.filters) {
        /*console.log(
          "feeds >>>>>>>>> pushState " +
            history.state.filters +
            " " +
            document.documentElement.scrollTop
        )*/
        if (history.state.filters in cache) {
          //console.log("saved to " + history.state.feed)
          cache[history.state.filters].scroll =
            document.documentElement.scrollTop
        }
      }
      let stateData: FeedInfo = {
        feed: 1000,
        reset: true,
        scroll: 0,
        filters: "",
        ts: Date.now(),
        kind: "comments",
      }
      history.pushState(stateData, "Comments", "/comments/" + item.id)
    }
    hideAllFeeds()
    setShowFeedback(false)
    setCommentItem(item)
    setSideMode(SideMode.Comment)
    //console.log(">>>>> setCommentItem " + item)
  }, [])

  const onShowDonations = useCallback(
    (item: Item) => {
      //console.log("showChannelItems " + item.id)
      if (window.history && window.history.pushState) {
        if (history.state && history.state.filters) {
          /*console.log(
            "feeds >>>>>>>>> pushState " +
              history.state.filters +
              " " +
              document.documentElement.scrollTop
          )*/
          if (history.state.filters in cache) {
            //console.log("saved to " + history.state.feed)
            cache[history.state.filters].scroll =
              document.documentElement.scrollTop
          }
        }
        let stateData: FeedInfo = {
          feed: 1000,
          reset: true,
          scroll: 0,
          filters: "",
          ts: Date.now(),
          kind: "comments",
        }
        history.pushState(stateData, "Donations", "/feedback/" + item.id)
      }
      hideAllFeeds()
      setShowFeedback(true)
      setCommentItem(item)
      setSideMode(SideMode.Donation)
      if (user && item.owner && item.owner == user.id)
        updateCommentFilters({ owned: true })
      //console.log(">>>>> setCommentItem " + item)
    },
    [user]
  )

  const onMute = useCallback((id: string, kind: MuteType) => {}, [])

  return (
    <div id="feedContainer" className="container">
      <div id="dfeed_0" className="feed">
        <CachedFeed
          feedId={0}
          initialChannelIds={initialChannelIds}
          showFeed={showFeed}
          currentFeed={currentFeed}
          scroll={scrollEvent}
          comment={comment}
          showDonations={onShowDonations}
        />
      </div>
      <div id="dfeed_1" className="feed hidden">
        <CachedFeed
          feedId={1}
          initialChannelIds={initialChannelIds}
          showFeed={showFeed}
          currentFeed={currentFeed}
          scroll={scrollEvent}
          comment={comment}
          showDonations={onShowDonations}
        />
      </div>
      <div id="dfeed_2" className="feed hidden">
        <CachedFeed
          feedId={2}
          initialChannelIds={initialChannelIds}
          showFeed={showFeed}
          currentFeed={currentFeed}
          scroll={scrollEvent}
          comment={comment}
          showDonations={onShowDonations}
        />
      </div>
      <div id="dfeed_3" className="feed hidden">
        <CachedFeed
          feedId={3}
          initialChannelIds={initialChannelIds}
          showFeed={showFeed}
          currentFeed={currentFeed}
          scroll={scrollEvent}
          comment={comment}
          showDonations={onShowDonations}
        />
      </div>
      <div id="dfeed_4" className="feed hidden">
        <CachedFeed
          feedId={4}
          initialChannelIds={initialChannelIds}
          showFeed={showFeed}
          currentFeed={currentFeed}
          scroll={scrollEvent}
          comment={comment}
          showDonations={onShowDonations}
        />
      </div>

      {commentItem && (
        <>
          <ItemPreview
            liked={filters.liked}
            item={commentItem}
            onLike={likeItem}
            onUpdate={updateItem}
            onMute={onMute}
            onShowComments={undefined}
          />

          {showFeedback ? (
            <Donations
              target={{
                kind: LikeType.Donate,
                id: commentItem?.id,
                url: commentItem?.url,
                count: 0,
                owned: user ? commentItem.owner == user.id : false,
              }}
              parentComment={""}
            />
          ) : (
            <Comments
              target={{
                kind: LikeType.Comment,
                id: commentItem?.id,
                url: commentItem?.url,
                count: commentItem?.comments_count,
              }}
              parentComment={""}
            />
          )}
        </>
      )}

      <style jsx>
        {`
          .container {
            position: relative;
            /*overflow: hidden;*/
          }

          .hidden {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default Feeds
